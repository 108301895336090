import React, { useEffect, useState } from 'react';
import Banner from '../../assets/images/about-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import * as styles from './index.module.less';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { MaterialInfoResult } from '../../services/interface';
import { getMaterialInfo } from '../../services';

export default function AboutPage() {
  const [info, setInfo] = useState<MaterialInfoResult>({
    win_32_download_url: '',
    win_64_download_url: '',
    linux_download_url: '',
    instructions_for_use: '',
    use_manual: '',
    use_operate_video: '',
  });
  useEffect(() => {
    getMaterialInfo().then((res) => {
      setInfo(res.data.data);
    });
  }, []);
  return (
    <Layout>
      <BannerWrap img={Banner} height={200} layout="center" title={<div>资料下载</div>} />
      <SectionWrap>
        <table className={styles.wrap}>
          <tbody>
            <tr>
              <td rowSpan={3}>文档</td>
              <td>
                <a href={info.instructions_for_use} target="_blank" download>
                  土星云分布式存储使用说明
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={info.use_manual} target="_blank" download>
                  土星云管理系统使用手册
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={info.use_operate_video} target="_blank" download>
                  土星云管理系统操作视频
                </a>
              </td>
            </tr>
            <tr>
              <td rowSpan={3}>软件</td>
              <td>
                <a href={info.linux_download_url} target="_blank" download>
                  土星云管理系统 （Linux）
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={info.win_32_download_url} target="_blank" download>
                  土星云管理系统 （Windows 32）
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href={info.win_64_download_url} target="_blank" download>
                  土星云管理系统 （Windows 64）
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="关于我们" />;
}
